<template>
  <div>
    <!-- Calendar -->
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        locale="id"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        v-if="calendarLoad"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="font-size: 13px">
            <p class="py-0">{{ selectedEvent.details }}</p>
            <!-- <p class="py-0">Keterangan : {{ selectedEvent.notes }}</p> -->
            <template v-if="selectedEvent.type == 'Perjanjian'">
              <p class="py-0">{{ selectedEvent.notes }}</p>
              <p class="py-0">{{ selectedEvent.status }}</p>
              <p class="py-0">{{ selectedEvent.action }}</p>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    isMyAppointment: Boolean,
    currentDoctor: Object,
  },

  data: () => ({
    // Calendar
    type: "month",
    typeToLabel: {
      month: "Per Bulan",
      week: "Per Minggu",
      day: "Per Hari",
      "4day": "Per 4 Hari",
    },
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    events: [],
    colors: [
      "red",
      "red lighten-5",
      "red lighten-4",
      "red lighten-3",
      "red lighten-2",
      "red lighten-1",
      "red darken-1",
      "red darken-2",
      "red darken-3",
      "red darken-4",
      "red accent-1",
      "red accent-2",
      "red accent-3",
      "red accent-4",
      "pink",
      "pink lighten-5",
      "pink lighten-4",
      "pink lighten-3",
      "pink lighten-2",
      "pink lighten-1",
      "pink darken-1",
      "pink darken-2",
      "pink darken-3",
      "pink darken-4",
      "pink accent-1",
      "pink accent-2",
      "pink accent-3",
      "pink accent-4",
      "purple",
      "purple lighten-5",
      "purple lighten-4",
      "purple lighten-3",
      "purple lighten-2",
      "purple lighten-1",
      "purple darken-1",
      "purple darken-2",
      "purple darken-3",
      "purple darken-4",
      "purple accent-1",
      "purple accent-2",
      "purple accent-3",
      "purple accent-4",
      "deep-purple",
      "deep-purple lighten-5",
      "deep-purple lighten-4",
      "deep-purple lighten-3",
      "deep-purple lighten-2",
      "deep-purple lighten-1",
      "deep-purple darken-1",
      "deep-purple darken-2",
      "deep-purple darken-3",
      "deep-purple darken-4",
      "deep-purple accent-1",
      "deep-purple accent-2",
      "deep-purple accent-3",
      "deep-purple accent-4",
      "indigo",
      "indigo lighten-5",
      "indigo lighten-4",
      "indigo lighten-3",
      "indigo lighten-2",
      "indigo lighten-1",
      "indigo darken-1",
      "indigo darken-2",
      "indigo darken-3",
      "indigo darken-4",
      "indigo accent-1",
      "indigo accent-2",
      "indigo accent-3",
      "indigo accent-4",
      "blue",
      "blue lighten-5",
      "blue lighten-4",
      "blue lighten-3",
      "blue lighten-2",
      "blue lighten-1",
      "blue darken-1",
      "blue darken-2",
      "blue darken-3",
      "blue darken-4",
      "blue accent-1",
      "blue accent-2",
      "blue accent-3",
      "blue accent-4",
      "light-blue",
      "light-blue lighten-5",
      "light-blue lighten-4",
      "light-blue lighten-3",
      "light-blue lighten-2",
      "light-blue lighten-1",
      "light-blue darken-1",
      "light-blue darken-2",
      "light-blue darken-3",
      "light-blue darken-4",
      "light-blue accent-1",
      "light-blue accent-2",
      "light-blue accent-3",
      "light-blue accent-4",
      "cyan",
      "cyan lighten-5",
      "cyan lighten-4",
      "cyan lighten-3",
      "cyan lighten-2",
      "cyan lighten-1",
      "cyan darken-1",
      "cyan darken-2",
      "cyan darken-3",
      "cyan darken-4",
      "cyan accent-1",
      "cyan accent-2",
      "cyan accent-3",
      "cyan accent-4",
      "teal",
      "teal lighten-5",
      "teal lighten-4",
      "teal lighten-3",
      "teal lighten-2",
      "teal lighten-1",
      "teal darken-1",
      "teal darken-2",
      "teal darken-3",
      "teal darken-4",
      "teal accent-1",
      "teal accent-2",
      "teal accent-3",
      "teal accent-4",
      "green",
      "green lighten-5",
      "green lighten-4",
      "green lighten-3",
      "green lighten-2",
      "green lighten-1",
      "green darken-1",
      "green darken-2",
      "green darken-3",
      "green darken-4",
      "green accent-1",
      "green accent-2",
      "green accent-3",
      "green accent-4",
      "light-green",
      "light-green lighten-5",
      "light-green lighten-4",
      "light-green lighten-3",
      "light-green lighten-2",
      "light-green lighten-1",
      "light-green darken-1",
      "light-green darken-2",
      "light-green darken-3",
      "light-green darken-4",
      "light-green accent-1",
      "light-green accent-2",
      "light-green accent-3",
      "light-green accent-4",
    ],

    // colors: ["bg-filed", "bt-conf-wait", "bt-conf", "bt-handled", "bg-danger"],
    // Event
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    calendarLoad: true,
  }),

  methods: {
    async getDoctorEvents() {
      const events = [];
      let doctor_id = this.isMyAppointment
        ? this.currentDoctor.id
        : this.$route.params.id;
      const doctorEvents = await module.list("doctors/get-events/" + doctor_id);
      const eventCount = doctorEvents.length;
      let usedColors = [];
      for (let i = 0; i < eventCount; i++) {
        // Cari warna yang belum digunakan
        let availableColors = this.colors.filter(
          (color) => !usedColors.includes(color)
        );
        if (availableColors.length === 0) {
          // Jika semua warna sudah terpakai, reset daftar warna yang digunakan
          usedColors = [];
          availableColors = this.colors.slice();
        }

        // Pilih warna acak dari daftar warna yang tersedia
        let randomIndex = this.random(0, availableColors.length - 1);
        let selectedColor = availableColors[randomIndex];
        usedColors.push(selectedColor); // Tandai warna sebagai sudah dipakai

        events.push({
          name: doctorEvents[i].name,
          type: doctorEvents[i].type,
          start: doctorEvents[i].start_time,
          end: doctorEvents[i].end_time,
          doctor_name: doctorEvents[i].doctor_name,
          notes: doctorEvents[i].notes,
          status_name: doctorEvents[i].appointment_status_name,
          action_type_name: doctorEvents[i].action_type_name,
          color: selectedColor,
          timed: true,
        });
      }
      this.events = await events;
      this.calendarLoad = true;
      console.info("events", this.events);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        if (event.type == "Perjanjian") {
          let dayName = new Date(event.start).toLocaleDateString("id-ID", {
            weekday: "long",
          });
          let start = new Date(event.start).toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
          });
          let end = new Date(event.end).toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
          });
          this.selectedEvent.details = `${event.name} Pada ${dayName} ${start} s/d ${end} dengan ${event.doctor_name}`;
          this.selectedEvent.notes = `Catatan: ${event.notes}`;
          this.selectedEvent.status = `Status: ${event.status_name}`;
          this.selectedEvent.action = `Tindakan: ${event.action_type_name}`;
        } else {
          let fullDate = new Date(event.start).toLocaleDateString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          });
          this.selectedEvent.details = `${event.name} Pada ${fullDate}`;
        }
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 30);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 30);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    viewDay({ date }) {
      console.log("date", date);
      this.value = date;
      this.type = "day";
    },

    setToday() {
      this.value = "";
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    getEventColor(event) {
      return event.color;
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  mounted() {
    this.getDoctorEvents();
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.bg-filed {
  background-color: #007bff !important;
}
.bg-conf-wait {
  background-color: #ffc107 !important;
  color: black !important;
}
.bg-conf {
  background-color: #28a745 !important;
}
.bg-handled {
  background-color: #17a2b8 !important;
}
</style>